/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import classNames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import makeClasses from 'hooks/useClasses';
import PropTypes from 'prop-types';
import React, { } from 'react';
import Stack from '@mui/material/Stack';
import styles from 'styles/jss/components/appDialogStyle';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';

const drawerBleeding = 56;

const drawers = {
  drawer: Drawer,
  swipeable: SwipeableDrawer
};

const WhichDrawer = ({
  onOpen,
  onOpenComplete,
  closable,
  disableSwipeToClose,
  disableSwipeToOpen,
  swipeAreaWidth,
  ...props
}) => {
  let passableProps = { };
  const TheDrawer = closable && !disableSwipeToClose ? drawers.swipeable : drawers.drawer;
  if (closable) {
    passableProps = {
      onOpen,
      disableSwipeToOpen,
      swipeAreaWidth
    };
  }
  return (
    <TheDrawer
      {...props}
      {...passableProps}
      SlideProps={{
        onEntered: onOpenComplete
      }}
    />
  );
};

WhichDrawer.propTypes = {
  closable: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onOpenComplete: PropTypes.func.isRequired,
  disableSwipeToOpen: PropTypes.bool.isRequired,
  disableSwipeToClose: PropTypes.bool.isRequired,
  swipeAreaWidth: PropTypes.number.isRequired
};

const DialogBody = ({
  bodyClass,
  buttons,
  children,
  closable,
  handleClose,
  handleDone,
  submitDisabled,
  submitLabel,
  title
}) => {
  let buttonActions = null;
  if (buttons && !_.isEmpty(buttons)) {
    buttonActions = buttons;
  } else if (handleDone) {
    buttonActions = (
      <Button
        color="primary"
        variant="contained"
        disabled={submitDisabled}
        onClick={handleDone}
      >
        {submitLabel}
      </Button>
    );
  }

  return (
    <Box className={bodyClass}>
      <DialogTitle
        className={classNames({
          noTitle: !title
        })}
      >
        {title}
        {closable && (
          <IconButton onClick={handleClose} className="closeIcon">
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      {buttonActions && (
        <DialogActions>
          <Stack spacing={2} sx={{ width: '100%' }}>
            {buttonActions}
          </Stack>
        </DialogActions>
      )}
    </Box>
  );
};

DialogBody.defaultProps = {
  bodyClass: null,
  buttons: null,
  closable: true,
  handleDone: null,
  title: null
};

DialogBody.propTypes = {
  bodyClass: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape()
  ]),
  buttons: PropTypes.arrayOf(
    PropTypes.node
  ),
  children: PropTypes.node.isRequired,
  closable: PropTypes.bool,
  title: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleDone: PropTypes.func,
  submitDisabled: PropTypes.bool.isRequired,
  submitLabel: PropTypes.string.isRequired
};

const AppDialog = ({
  classes: dialogClasses,
  className,
  closable,
  disableSwipeToClose,
  fullWidth,
  open,
  handleClose,
  onOpenComplete,
  maxWidth,
  sx,
  ...props
}) => {
  const classes = makeClasses(styles);
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  const closeDialog = () => {
    if (!closable) { return; }
    handleClose();
  };

  if (!isMedium) {
    onOpenComplete();
  }

  return isMedium ? (
    <WhichDrawer
      // container={container}
      anchor="bottom"
      className={classNames(classes.swipeableDrawerBackdrop, className)}
      classes={{
        paper: classes.swipeableDrawer,
        ...dialogClasses
      }}
      closable={closable}
      disableSwipeToClose={disableSwipeToClose}
      open={open}
      onClose={() => closeDialog()}
      onOpen={() => {}}
      onOpenComplete={onOpenComplete}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen
      ModalProps={{
        keepMounted: false
      }}
      sx={sx}
    >
      <Box
        className={classNames(classes.drawerPuller, {
          closable
        })}
        onClick={() => {
          if (disableSwipeToClose) {
            closeDialog();
          }
        }}
      />
      <DialogBody handleClose={closeDialog} closable={closable} {...props} />
    </WhichDrawer>
  ) : (
    <Dialog
      open={open}
      onClose={closeDialog}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      sx={sx}
      className={classNames(classes.appDialog, className)}
      classes={dialogClasses}
    >
      <DialogBody handleClose={closeDialog} closable={closable} {...props} />
    </Dialog>
  );
};

AppDialog.defaultProps = {
  bodyClass: null,
  classes: {},
  className: null,
  closable: true,
  disableSwipeToClose: false,
  fullWidth: true,
  handleDone: null,
  onOpenComplete: () => {},
  maxWidth: 'xs',
  submitDisabled: false,
  submitLabel: 'Done',
  sx: {},
  title: null
};

AppDialog.propTypes = {
  bodyClass: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape()
  ]),
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape(),
  closable: PropTypes.bool,
  className: PropTypes.string,
  disableSwipeToClose: PropTypes.bool,
  fullWidth: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleDone: PropTypes.func,
  maxWidth: PropTypes.string,
  onOpenComplete: PropTypes.func,
  open: PropTypes.bool.isRequired,
  submitDisabled: PropTypes.bool,
  submitLabel: PropTypes.string,
  sx: PropTypes.shape(),
  title: PropTypes.string
};

export default AppDialog;
