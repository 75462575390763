import React from 'react';
import AppHeader from 'components/Header/Header';
import Box from '@mui/material/Box';
import ChatWootProvider from 'contexts/ChatWootProvider';
import ConfirmDialog from 'components/Dialog/ConfirmDialog';
import Footer from 'components/Footer/Footer';
import Grid from '@mui/material/Grid';
import makeClasses from 'hooks/useClasses';
import PropTypes from 'prop-types';
import styles from 'styles/jss/layouts/defaultLayoutStyle';

const DefaultLayout = ({ children }) => {
  const classes = makeClasses(styles);
  return (
    <>
      <ConfirmDialog />
      <ChatWootProvider>
        <Box sx={{ height: '100vh', flexDirection: 'column', display: 'flex' }}>
          <AppHeader />
          <Grid container item className={classes.layoutContainer} xs={12}>
            {children}
          </Grid>
          <Footer />
        </Box>
      </ChatWootProvider>
    </>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default DefaultLayout;
