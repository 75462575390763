const defaultLayoutStyle = (theme) => ({
  layoutContainer: {
    width: '100%',
    flexGrow: 1,
    alignItems: 'start',
    a: {
      color: theme.palette.primary.main,
      textDecoration: 'none',

      '&:not(.MuiButtonBase-root):hover': {
        textDecoration: 'underline',
        cursor: 'pointer',

        p: {
          textDecoration: 'none'
        }
      }
    }
  }
});

export default defaultLayoutStyle;
