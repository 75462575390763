import { useTheme } from '@mui/material/styles';
import {
  PATH_AFFILIATES,
  PATH_BLOG,
  PATH_CONTACT,
  PATH_FAQ,
  PATH_PRIVACY,
  PATH_TERMS,
  // TWITTER_URL,
  // TWITTER_HANDLE,
  INSTAGRAM_HANDLE,
  INSTAGRAM_URL,
  FB_URL,
  FB_HANDLE,
  PINTEREST_HANDLE,
  PINTEREST_URL
} from 'constants';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from 'next/link';
import makeClasses from 'hooks/useClasses';
import MuiLink from '@mui/material/Link';
import React from 'react';
import Stack from '@mui/material/Stack';
import styles from 'styles/jss/components/footerStyle';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

// import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import PinterestIcon from '@mui/icons-material/Pinterest';

const social = [
  // {
  //   url: TWITTER_URL,
  //   alt: `Twitter ${TWITTER_HANDLE}`,
  //   icon: <TwitterIcon />
  // },
  {
    url: INSTAGRAM_URL,
    alt: `Instagram ${INSTAGRAM_HANDLE}`,
    icon: <InstagramIcon />
  },
  {
    url: FB_URL,
    alt: `Facebook ${FB_HANDLE}`,
    icon: <FacebookIcon />
  },
  {
    url: PINTEREST_URL,
    alt: `Pinterest ${PINTEREST_HANDLE}`,
    icon: <PinterestIcon />
  }
];

const Footer = () => {
  const theme = useTheme();
  const classes = makeClasses(styles);
  const d = new Date();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <footer className={classes.footer}>
      <Grid
        container
        spacing={3}
        sx={{
          px: { xs: 4, md: 6 },
          py: 6
        }}
      >
        <Grid
          item
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          xs={12}
        >
          <Stack
            className={classes.footerLinks}
            direction="row"
            spacing={{ xs: 5, md: 5 }}
          >
            <Link href={PATH_PRIVACY} passHref>
              <MuiLink color="inherit" underline="hover" component="button">
                {isSmall ? 'Privacy' : 'Privacy Policy'}
              </MuiLink>
            </Link>
            <Link href={PATH_TERMS} passHref>
              <MuiLink color="inherit" underline="hover" component="button">
                Terms of Service
              </MuiLink>
            </Link>
            <Link href={PATH_CONTACT} passHref>
              <MuiLink color="inherit" underline="hover" component="button">
                Contact
              </MuiLink>
            </Link>
            <Link href={PATH_BLOG} passHref>
              <MuiLink color="inherit" underline="hover" component="button">
                Blog
              </MuiLink>
            </Link>
            <Link href={PATH_FAQ} passHref>
              <MuiLink color="inherit" underline="hover" component="button">
                FAQ
              </MuiLink>
            </Link>
            <Link href={PATH_AFFILIATES} passHref>
              <MuiLink color="inherit" underline="hover" component="button">
                Affiliates
              </MuiLink>
            </Link>
          </Stack>
        </Grid>
        <Grid
          className={classes.socialLinks}
          item
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          xs={12}
        >
          <Stack direction="row" spacing={{ xs: 4, md: 5 }}>
            {social.map((s) => (
              <Link key={s.url} href={s.url} target="_blank" passHref>
                <IconButton
                  size="small"
                  color="white"
                  target="_blank"
                  component="a"
                >
                  {s.icon}
                </IconButton>
              </Link>
            ))}
          </Stack>
        </Grid>
        <Grid
          item
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          xs={12}
        >
          <Typography textAlign="center" className={classes.copyright}>
            &copy;
            {` ${d.getFullYear()} `}
            Scribble Creativity, Inc. All Rights Reserved.
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
