/* eslint-disable react/jsx-props-no-spreading */
import Image from 'next/image';
import PropTypes from 'prop-types';
import React from 'react';
import qs from 'qs';

const cloudflareImageLoader = ({ src, width, quality = 75 }) => {
  const image = src.indexOf('http') > -1 ? src : `${process.env.NEXT_PUBLIC_APP_DOMAIN}${src}`;
  const query = qs.stringify({
    width,
    quality,
    image
  });
  return `https://${process.env.NEXT_PUBLIC_CLOUDFLARE_IMAGE_WORKER}?${query}`;
};

const ImageLoader = (props) => {
  const { src } = props;
  if (!src) return null;
  if (process.env.NODE_ENV === 'development') {
    return <Image {...props} />;
  }
  return <Image {...props} loader={cloudflareImageLoader} />;
};

ImageLoader.defaultProps = {
  src: null
};

ImageLoader.propTypes = {
  src: PropTypes.string
};

export default ImageLoader;
