import { useRouter } from 'next/router';
import { v4 as uuidv4 } from 'uuid';
import { useAuth0 } from '@auth0/auth0-react';
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo
} from 'react';
import PropTypes from 'prop-types';

export const AnalyticsContext = createContext();
export const AnalyticsConsumer = () => useContext(AnalyticsContext);

/**
 * NOTE: CHANGES HERE NEED TO BE MANUALLY COPIED TO THE BLOG REPO
 * @param {*} param0
 * @returns
 */
const AnalyticsProvider = ({ children }) => {
  const [analytics, setAnalytics] = useState();
  const [clientId, setClientId] = useState();
  const router = useRouter();
  const { user } = useAuth0();

  useEffect(() => {
    (async () => {
      if (process.env.NEXT_PUBLIC_RUDDERSTACK_KEY) {
        const rudderAnalytics = await import('rudder-sdk-js');

        rudderAnalytics.load(
          process.env.NEXT_PUBLIC_RUDDERSTACK_KEY,
          process.env.NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL,
          {
            integrations: { All: true } // load call options
          }
        );

        rudderAnalytics.ready(() => {});

        setAnalytics(rudderAnalytics);
      }
    })();
  }, []);

  // https://www.rudderstack.com/docs/event-spec/standard-events/identify/#user-id-vs-anonymous-id
  // https://www.rudderstack.com/docs/destinations/streaming-destinations/google-analytics-4/google-analytics-4-cloud-mode/#page
  /**
   * Some version of a clientId is required for GA4
   */
  useEffect(() => {
    if (user) {
      setClientId(user.sub);
    } else {
      setClientId(uuidv4());
    }
  }, [user]);

  const getOptions = () => ({
    externalId: [
      {
        type: 'ga4ClientId',
        id: clientId
      }
    ]
  });

  const getProperties = () => ({
    referrer: document.referrer,
    search: router.query,
    // https://www.rudderstack.com/docs/destinations/streaming-destinations/fb-pixel/#event_id-method-recommended
    event_id: router.pathname
  });

  // Handle direct page loads
  useEffect(() => {
    if (analytics && router.isReady) {
      analytics.page(getProperties(), getOptions());
    }
  }, [analytics, router.isReady]);

  // Handle all other router page changes
  useEffect(() => {
    if (analytics) {
      const handleRouteChange = () => {
        analytics.page(getProperties(), getOptions());
      };
      // When the component is mounted, subscribe to router changes
      // and log those page views
      router.events.on('routeChangeComplete', handleRouteChange);

      // If the component is unmounted, unsubscribe
      // from the event with the `off` method
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
    return null;
  }, [analytics, router.events]);

  const track = (
    event,
    _properties = {},
    apiOptions = {},
    callback = () => {}
  ) => {
    const properties = {
      ..._properties,
      event_id: event // For Facebook
    };
    return analytics.track(event, properties, apiOptions, callback);
  };

  const identify = (
    userId,
    _traits = {},
    apiOptions = {},
    callback = () => {}
  ) => {
    const traits = {
      ..._traits
    };
    return analytics.identify(userId, traits, apiOptions, callback);
  };

  const value = useMemo(
    () => ({
      track,
      identify,
      analytics
    }),
    [analytics]
  );

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

AnalyticsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default AnalyticsProvider;
