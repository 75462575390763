import { createTheme } from '@mui/material/styles';
import {
  infoColor,
  roseColor
} from 'styles/jss/nextjs-material-kit-pro';
import { grey } from '@mui/material/colors';

const themeBase = createTheme({
  spacing: 4,
  palette: {
    primary: {
      main: roseColor[0],
      light: roseColor[3],
      dark: roseColor[2]
    },
    secondary: {
      main: infoColor[0],
      light: infoColor[4],
      dark: infoColor[0]
    },
    grey: {
      main: grey[500],
      light: grey[300],
      superLight: grey[100],
      dark: grey[800]
    },
    notBlack: {
      main: grey[800]
    },
    white: {
      main: '#FFF'
    }
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 14,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '300',
    lineHeight: '1.5em',
    marginBottom: 20,
    pageTitle: {
      fontSize: '2.3rem',
      color: roseColor[0],
      textDecoration: 'none',
      fontWeight: '700',
      marginBottom: '20px',
      minHeight: '32px',
      fontFamily: '"Roboto Slab", "Times New Roman", serif'
    },
    h1: {
      fontSize: '1.9rem',
      color: grey[800],
      textDecoration: 'none',
      fontWeight: '700',
      marginTop: '30px',
      marginBottom: '20px'
    },
    h2: {
      fontSize: '1.7rem',
      color: grey[800],
      textDecoration: 'none',
      fontWeight: '700',
      marginTop: '30px',
      marginBottom: '20px'
    },
    h3: {
      fontSize: '1.8rem'
    },
    h4: {
      fontSize: '1.6rem'
    },
    p: {
      marginBlockStart: '1em',
      marginBlockEnd: '1em',
      marginInlineStart: 0,
      marginInlineEnd: 0
    },
    body1: {
      // paddingBottom: 20
    },
    subtitle1: {
      fontSize: '1.06rem',
      color: grey[500],
      lineHeight: '1.6rem'
    },
    subtitle2: {
      fontSize: '1.0rem',
      color: grey[500],
      fontWeight: 'normal'
    },
    small: {
      fontSize: '0.8em'
    },
    bookPreview: {
      fontFamily: 'Amatic SC, cursive'
    }
  },
  components: {}
});

const theme = createTheme({
  ...themeBase,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          '&.MuiButton-containedPrimary': {
            backgroundColor: themeBase.palette.primary.main,
            color: '#FFF',
            '&:hover': {
              backgroundColor: themeBase.palette.primary.dark
            },
            '&.Mui-disabled': {
              backgroundColor: themeBase.palette.grey.light,
              color: themeBase.palette.grey.main,
              borderColor: themeBase.palette.grey.light,
              '&:hover': {
                backgroundColor: themeBase.palette.primary.dark
              }
            }
          },
          '&.MuiButton-outlinedPrimary': {
            // backgroundColor: 'transparent',
            color: themeBase.palette.primary.main,
            borderColor: themeBase.palette.primary.main,
            borderWidth: 1,
            borderStyle: 'solid',
            '&:hover': {
              // backgroundColor: 'transparent',
              color: themeBase.palette.primary.dark,
              borderColor: themeBase.palette.primary.dark
            },
            '&.Mui-disabled': {
              color: themeBase.palette.grey.light,
              borderColor: themeBase.palette.grey.light,
              '&:hover': {
                backgroundColor: themeBase.palette.primary.dark
              }
            }
          }
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          // borderWidth: 1,
          // borderStyle: 'solid',
          // borderColor: roseColor[0]
          display: 'flex'
        }
      }
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          // borderWidth: 1,
          // borderStyle: 'solid',
          // borderColor: roseColor[0]
          padding: 7
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: 4
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 20
        }
      }
    },
    MuiGrid: {
      variants: [
        {
          props: { variant: 'appLayout' },
          style: {
            [themeBase.breakpoints.down('sm')]: {
              marginTop: 0
            },
            [themeBase.breakpoints.up('sm')]: {
              maxWidth: 'calc(100% - 20px)'
            },
            // '@media (min-width: 992px)': {
            [themeBase.breakpoints.up('md')]: {
              maxWidth: '960px'
            },
            [themeBase.breakpoints.up('lg')]: {
              maxWidth: '1140px'
            }
          }
        }
      ]
    }
  }
});
export default theme;
