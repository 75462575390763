const footer = (theme) => ({
  footer: {
    marginTop: 0,
    color: '#FFF',
    width: '100%',
    border: 'none',
    backgroundColor: theme.palette.primary.main,
    display: 'flex'
  },

  footerLinks: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '0.9rem',
    flexWrap: 'wrap',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      // Force wrapping so that there isn't one link dangling
      padding: '0 30px',
      lineHeight: '2rem'
    },

    button: {
      fontSize: '0.95em',
      display: 'flex',
      whiteSpace: 'nowrap'
    }
  },

  socialLinks: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },

  copyright: {
    fontSize: '0.8em'
  }
});
export default footer;
