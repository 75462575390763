import _ from 'lodash';
import React, { } from 'react';
import AppDialog from 'components/Dialog/AppDialog';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ConfirmDialogConsumer } from 'contexts/ConfirmDialogProvider';
import { useTheme } from '@mui/material/styles';

const ConfirmDialog = () => {
  const { confirmDialog, closeConfirmDialog } = ConfirmDialogConsumer();
  const theme = useTheme();
  const {
    title = 'Confirm',
    message,
    closeAction = () => {},
    confirmAction = () => {},
    confirmButtonLabel = 'Confirm'
  } = confirmDialog || {};

  const closeDialog = async () => {
    await closeAction();
    closeConfirmDialog();
  };

  const confirm = async () => {
    await confirmAction();
    closeConfirmDialog();
  };

  return (
    <AppDialog
      open={!_.isEmpty(confirmDialog)}
      handleClose={closeDialog}
      handleSubmit={confirm}
      title={title}
      sx={{
        // Ensure that this sits above typical modals
        zIndex: theme.zIndex.modal + 10
      }}
      buttons={[
        <Button color="primary" variant="contained" onClick={confirm} key="close">
          {confirmButtonLabel}
        </Button>
      ]}
    >
      <Typography>
        {message}
      </Typography>
    </AppDialog>
  );
};

export default ConfirmDialog;
