/* eslint-disable react/jsx-props-no-spreading */
// import App from "next/app";
// import PageChange from 'components/PageChange/PageChange.js';
// import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createEmotionCache from 'utils/createEmotionCache';
import ConfirmDialogProvider from 'contexts/ConfirmDialogProvider';
import AnalyticsProvider from 'contexts/AnalyticsProvider';
import CssBaseline from '@mui/material/CssBaseline';
import DefaultLayout from 'layouts/DefaultLayout';
import Head from 'next/head';
import PropTypes from 'prop-types';
import React from 'react';
import theme from 'styles/jss/muiThemes/appTheme';

// TODO Look at backdrop instead for more elegant
// https://material-ui.com/components/backdrop/

// Router.events.on("routeChangeStart", (url) => {
//   console.log(`Loading: ${url}`);
//   document.body.classList.add("body-page-transition");
//   ReactDOM.render(
//     <PageChange path={url} />,
//     document.getElementById("page-transition")
//   );
// });
// Router.events.on("routeChangeComplete", () => {
//   ReactDOM.unmountComponentAtNode(document.getElementById("page-transition"));
//   document.body.classList.remove("body-page-transition");
// });
// Router.events.on("routeChangeError", () => {
//   ReactDOM.unmountComponentAtNode(document.getElementById("page-transition"));
//   document.body.classList.remove("body-page-transition");
// });

// export default class MyApp extends App {
//   static async getInitialProps({ Component, router, ctx }) {
//     let pageProps = {};

//     if (Component.getInitialProps) {
//       pageProps = await Component.getInitialProps(ctx);
//     }

//     return { pageProps };
//   }
//   render() {
//     const { Component, pageProps } = this.props;

//     return (
//       <React.Fragment>
//         <UserProvider>
//           <DefaultLayout>
//             <Component {...pageProps} />
//           </DefaultLayout>
//         </UserProvider>
//       </React.Fragment>
//     );
//   }
// }

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const App = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps
}) => {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout
    || ((page) => <DefaultLayout showFooter={false}>{page}</DefaultLayout>);

  return (
    <CacheProvider value={emotionCache}>
      <Auth0Provider
        domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN}
        clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}
        audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE}
        redirectUri={`${process.env.AUTH0_REDIRECT_URL}/auth-callback/`}
        scope={process.env.NEXT_PUBLIC_AUTH0_SCOPES}
        cacheLocation="localstorage"
        useRefreshTokens
      >
        <AnalyticsProvider>
          <Head>
            <meta charSet="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
          </Head>
          <MaterialThemeProvider theme={theme}>
            <CssBaseline />
            <ConfirmDialogProvider>
              {getLayout(<Component {...pageProps} />)}
            </ConfirmDialogProvider>
          </MaterialThemeProvider>
        </AnalyticsProvider>
      </Auth0Provider>
    </CacheProvider>
  );
};

App.defaultProps = {
  pageProps: {},
  emotionCache: undefined
};

App.propTypes = {
  Component: PropTypes.func.isRequired,
  emotionCache: PropTypes.shape(),
  pageProps: PropTypes.shape()
};

export default App;
