import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

export const ConfirmDialogContext = createContext();

export const ConfirmDialogConsumer = () => useContext(ConfirmDialogContext);

const ConfirmDialogProvider = ({ children }) => {
  const [confirmDialog, setConfirmDialog] = useState(false);

  const showConfirmDialog = ({
    title,
    message,
    closeAction,
    confirmAction,
    confirmButtonLabel,
    closeButtonLabel
  }) => {
    setConfirmDialog({
      closeAction,
      closeButtonLabel,
      confirmAction,
      confirmButtonLabel,
      message,
      title
    });
  };

  const closeConfirmDialog = () => {
    setConfirmDialog(null);
  };

  const value = React.useMemo(() => ({
    confirmDialog,
    showConfirmDialog,
    closeConfirmDialog
  }), [confirmDialog]);

  return (
    <ConfirmDialogContext.Provider value={value}>
      {children}
    </ConfirmDialogContext.Provider>
  );
};

ConfirmDialogProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default ConfirmDialogProvider;
