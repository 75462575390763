const appDialogStyle = (theme) => ({
  swipeableDrawerBackdrop: {
    // Ensure it sits higher than the drawer
    zIndex: theme.zIndex.modal + 20
  },

  swipeableDrawer: {
    borderRadius: '10px 10px 0 0',

    '.MuiDialogTitle-root': {
      position: 'relative',
      color: theme.palette.primary.main,
      fontSize: '1.5em',
      paddingTop: 0,

      '&.noTitle': {
        display: 'none'
      },

      '.closeIcon': {
        // Let's hide it for now. We don't need too many ways to hide on mobile
        display: 'none',
        position: 'absolute',
        top: 10,
        right: 8
      }
    },

    '.MuiDialogActions-root': {
      flexDirection: 'column-reverse',
      button: {
        width: '100%',
        marginLeft: 0
      }
    },

    '.MuiDialogContent-root .MuiDialogActions-root': {
      // If the DialogActions are nested inside the DialogContent
      padding: '20px 0 0 0'
    }
  },

  drawerPuller: {
    position: 'relative',
    height: 6,
    marginTop: 9,
    marginBottom: 15,

    '&:after': {
      content: '""',
      width: 50,
      height: '100%',
      backgroundColor: theme.palette.grey.light,
      borderRadius: 3,
      position: 'absolute',
      top: 0,
      display: 'none',
      left: 'calc(50% - 25px)'
    },

    '&.closable&:after': {
      display: 'block'
    }
  },

  appDialog: {
    '.MuiDialogTitle-root': {
      position: 'relative',
      color: theme.palette.primary.main,
      fontSize: '1.4em',

      '.closeIcon': {
        position: 'absolute',
        top: 10,
        right: 8
      }
    },
    '.MuiDialogActions-root': {
      flexDirection: 'row',

      '.backButton': {
        // Force the back button to the left side of the modal
        marginRight: 'auto'
      }
    },
    '.MuiDialogContent-root .MuiDialogActions-root': {
      // If the DialogActions are nested inside the DialogContent
      padding: '20px 0 0 0'
    }
  }
});

export default appDialogStyle;
