/* eslint-disable no-underscore-dangle */
/* eslint-disable object-property-newline */
import _ from 'lodash';
import React, { createContext, useContext, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import hash from 'hash.js';
import Script from 'next/script';

export const ChatWootContext = createContext();
export const ChatWootConsumer = () => useContext(ChatWootContext);

const CHATWOOT_BASE_URL = 'https://app.chatwoot.com';
const CHATWOOT_KEY = process.env.NEXT_PUBLIC_CHATWOOT_KEY;

const ChatWootProvider = ({ children }) => {
  const router = useRouter();
  const { user } = useAuth0();

  const showChat = (toggle) => {
    const sub = _.get(user, 'sub');
    const hmac = hash.hmac(hash.sha256, CHATWOOT_KEY).update(sub).digest('hex');
    const name = _.get(user, 'name');
    const email = _.get(user, 'email');
    const username = _.get(user, 'sub');

    const attributes = {
      path: router.asPath
    };

    if (user) {
      window.$chatwoot.setUser(username, {
        email,
        // If the user doesn't have a name (email is used)
        name: name !== email ? name : null,
        avatar_url: _.get(user, 'picture'),
        identifier_hash: hmac
      });
      attributes.username = username;
    }

    window.$chatwoot.setCustomAttributes(attributes);

    const toggleAction = toggle ? 'open' : 'close';
    window.$chatwoot.toggle(toggleAction);
  };

  const value = useMemo(() => ({
    showChat
  }), [user, router]);

  return (
    <ChatWootContext.Provider value={value}>
      <>
        <Script
          id="chat-woot"
          strategy="lazyOnload"
          src={`${CHATWOOT_BASE_URL}/packs/js/sdk.js`}
          onLoad={() => {
            window.chatwootSettings = {
              hideMessageBubble: true,
              position: 'right', // This can be left or right
              locale: 'en', // Language to be set
              type: 'standard' // [standard, expanded_bubble]
            };
            window.chatwootSDK.run({
              websiteToken: 'E3qjsipLDJtHwsTPYK1Qd3Xq',
              baseUrl: CHATWOOT_BASE_URL
            });
          }}
        />
        {children}
      </>
    </ChatWootContext.Provider>
  );
};

ChatWootProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default ChatWootProvider;
