/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

// Meta
const META_PREFIX = 'Scribble |';
export const HOME_META_TITLE = 'Scribble | Instant hardcover photo books from children\'s art';
// export const HOME_META_DESCRIPTION = 'Scribble is building the future of how families celebrate their young creator\'s unbridled imagination.';
export const HOME_META_DESCRIPTION = 'Effortlessly create beautiful and affordable hardcover art books of your young student\'s artwork. Just snap a picture of your artwork on any surface and watch it magically build a book. Books start at $39.99.';
export const HOME_META_IMAGE = `${process.env.NEXT_PUBLIC_APP_DOMAIN}/img/head/meta-image.jpg`;
export const HOME_META_VIDEO = `${process.env.NEXT_PUBLIC_APP_DOMAIN}/scribble_hero.mp4'`;
export const PROJECT_PUBLIC_DESCRIPTION_META = 'Effortlessly create beautiful and affordable hardcover art books of your young student\'s artwork. Just snap a picture of your artwork on any surface and watch it magically build a book. Books start at $39.99.';
export const PRIVACY_META_TITLE = `${META_PREFIX} Privacy Policy`;
export const PRIVACY_META_DESCRIPTION = 'Scribble Official Privacy Policy. Scribble is committed to your online privacy.';
export const TERMS_META_TITLE = `${META_PREFIX} Terms of Service`;
export const TERMS_META_DESCRIPTION = 'Scribble Official Terms of Service.';
export const BLOG_META_TITLE = 'The Art Room | The Scribble Blog';
export const BLOG_META_DESCRIPTION = 'Children\'s creativity stories, inspirational conversations, and creative tips for capturing and celebrating our children\'s creative moments.';
export const FAQ_META_TITLE = 'Scribble FAQ';
export const FAQ_META_DESCRIPTION = 'How can we help? Get your Scribble product, orders, and shipping questions answered here';

// Paths
export const PATH_HOME = '/';
export const PATH_PRIVACY = '/privacy/';
export const PATH_TERMS = '/terms/';
export const PATH_BLOG = '/blog/';
export const PATH_CONTACT = '/contact/';
export const PATH_AFFILIATES = '/affiliates/';
export const PATH_FAQ = '/faq/';
export const PATH_PROJECTS_PREVIEW = '/projects/preview/';
export const PATH_CREATOR_ABOUT = '/creator/about/';
export const PATH_CREATOR_AUTHOR = '/creator/author/';
export const PATH_CREATOR_UPLOAD = '/creator/upload/';
export const PATH_CREATOR_SHIPPING = '/creator/shipping/';
export const PATH_CREATOR_ORDER_SUMMARY = '/creator/order-summary/';
export const PATH_CREATOR_ORDER_CONFIRMATION = '/creator/confirmation/';
export const PATH_PRODUCT_11X85 = '/products/books/11x8.5-kids-hardcover-art-photo-book/';
export const PATH_PRODUCT_85X85 = '/products/books/8.5x8.5-kids-hardcover-art-photo-book/';

// Social
export const TWITTER_HANDLE = '@scribble_create';
export const TWITTER_URL = 'https://twitter.com/scribble_create';
export const INSTAGRAM_HANDLE = '@scribble_creativity';
export const INSTAGRAM_URL = 'https://www.instagram.com/scribble_creativity/';
export const FB_APP_ID = '2233308776817550';
export const FB_HANDLE = '@scribble.creativity';
export const FB_URL = 'https://www.facebook.com/scribble.creativity/';
export const PINTEREST_HANDLE = '@scribble_creativity';
export const PINTEREST_URL = 'https://www.pinterest.com/scribble_creativity/';

// Content
export const RETURNS_BODY = 'All sales are final and no refunds will be issued, except in the case of damaged or defective items. Claims must be made within 30 days of original delivery.';

// Webiny
export const AFFILIATE_FAQ_ID = '64e8d10b0af6c40008437e1b';
