import _ from 'lodash';
import {
  PATH_BLOG,
  PATH_FAQ,
  PATH_HOME,
  PATH_PRODUCT_11X85,
  PATH_PRODUCT_85X85
} from 'constants';
import { useAuth0 } from '@auth0/auth0-react';
import { useRouter } from 'next/router';
import AppBar from '@mui/material/AppBar';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import IconButton from '@mui/material/IconButton';
import Image from 'components/Image';
import Link from 'next/link';
import ListItemIcon from '@mui/material/ListItemIcon';
import LoginIcon from '@mui/icons-material/Login';
import Logout from '@mui/icons-material/Logout';
import makeClasses from 'hooks/useClasses';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from 'styles/jss/components/headerStyle';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const PRODUCTS = [
  {
    title: '11" x 8.5" Hardcover Kid\'s Art Photo Book',
    url: PATH_PRODUCT_11X85,
    thumb: '/img/products/11x8-5_marble_thumb.jpg'
  },
  {
    title: '8.5" x 8.5" Hardcover Kid\'s Art Photo Book',
    url: PATH_PRODUCT_85X85,
    thumb: '/img/products/8-5x8-5_marble_thumb.jpg'
  }
];

const Header = () => {
  const classes = makeClasses(styles);
  const [anchorLeftMenu, setAnchorLeftMenu] = useState(null);

  const handleOpenLeftMenu = (event) => {
    setAnchorLeftMenu(event.currentTarget);
  };

  const handleCloseLeftMenu = () => {
    setAnchorLeftMenu(null);
  };


  return (
    <AppBar position="static" className={classes.appBar} id="appToolbar">
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 0 }} display="flex">
            <Tooltip title="Menu">
              <IconButton onClick={handleOpenLeftMenu} sx={{ p: 0 }}>
                <MenuIcon color="white" />
              </IconButton>
            </Tooltip>
            <Menu
              className={classes.productMenu}
              sx={{ mt: '45px' }}
              disableScrollLock
              anchorEl={anchorLeftMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorLeftMenu)}
              onClose={handleCloseLeftMenu}
            >
              <Box>
                <Typography className="productHeader" color="primary">
                  Our Products
                </Typography>
              </Box>
              {PRODUCTS.map((product) => (
                <Link href={product.url} passHref key={product.title}>
                  <MenuItem onClick={handleCloseLeftMenu} sx={{ width: 350 }}>
                    <Image
                      src={product.thumb}
                      width={150}
                      height={113}
                      priority
                    />
                    <Box>{product.title}</Box>
                  </MenuItem>
                </Link>
              ))}
              <Divider sx={{ my: 0.5 }} />
              <Link href={PATH_FAQ} passHref>
                <MenuItem onClick={handleCloseLeftMenu}>
                  <ListItemIcon>
                    <HelpCenterOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  FAQ
                </MenuItem>
              </Link>
              <Link href={PATH_BLOG} passHref>
                <MenuItem onClick={handleCloseLeftMenu}>
                  <ListItemIcon>
                    <AutoStoriesOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  Blog
                </MenuItem>
              </Link>
            </Menu>
          </Box>
          <Box
            className={classes.logoBox}
            sx={{
              justifyContent: 'center'
            }}
          >
            <a
              alt="Scribble home"
              href={PATH_HOME}
              style={{
                display: 'flex',
                position: 'relative',
                width: 121,
                height: 31
              }}
            >
              <Image
                src="/img/logo_nav@2x.png"
                width={121}
                height={31}
                priority
              />
            </a>
          </Box>
          <Box sx={{ flexGrow: 0 }} />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
